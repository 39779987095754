import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
// import myteam from '../assets/myteam.jpeg';
import useStyles from './landingpagestyle';

const Hero = () => {
  const classes = useStyles();

  return (
    <Box className={classes.heroBox}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Do What Matters Most
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
          Improve productivity and prioritization in your life and work. 
      Organize and prioritize your tasks, list and projects with Inotask.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="/register"
            sx={{ width: '200px', backgroundColor: '#EEB419', color: '#fff', fontSize: '16px', ':hover': {
              bgcolor: '#f2f0f1',
              color: '#000',
            }, }}
          >
            Get Started Now
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={"../assets/small-team-discussing-ideas-2.png"} alt="Discussing team priorities" className={classes.largeImage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;