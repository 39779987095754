import React from 'react';
import { Typography, Box, Card, CardContent, Grid } from '@mui/material';
// import { Avatar } from '@mui/material';
import useStyles from './aboutstyle';

const Testimonial = () => {
  const classes = useStyles();
  const reviews = [
    {
      id: 1,
      // name: 'Karl Brighton',
      statement:
        'Inotask has helped me clarify my goals and objectives, and ensure that my tasks and projects are aligned with these goals. The tool helps me stay focused and motivated, and avoid wasting time on activities that does not contribute to my goals.',
      // image_url:
      //   'https://sweta-myteam-website-fm.netlify.app/static/media/avatar-kady.78fc482c.jpg',
      // position: 'Software Engineer at Kadex',
    },
    {
      id: 2,
      // name: 'Krishna Bells',
      statement:
        'I have developed the habit of evaluating the importance of different tasks and projects, and identify the ones that are most critical to my success and goals. This have helped me prioritize my time effectively, and focus on the tasks that will have the greatest impact.',
      // image_url:
      //   'https://sweta-myteam-website-fm.netlify.app/static/media/avatar-aiysha.e119a0c1.jpg',
      // position: 'Product Manager at Google',
    },
    {
      id: 3,
      // name: 'Ben Spiff',
      statement:
        'This tool provides a structured approach for comparing and evaluating different tasks and projects, and have helped me make more informed decisions about which ones to focus on. This helps me avoid making decisions based on subjective factors such as personal preference.',
      // image_url:
      //   'https://sweta-myteam-website-fm.netlify.app/static/media/avatar-arthur.098c2e26.jpg',
      // position: 'Founder of Crypto',
    },
  ];
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
      }}
    >
      <Grid container spacing={2}>
        {reviews.map((review) => (
          <Grid item sm={12} md={4} key={review.id}>
            <Card className={classes.testimonialCard}>
              <CardContent>
                <Typography className={classes.testimonialStatement}>
                  "{review.statement}"
                </Typography>
                {/* <Box sx={{ display: 'flex' }}>
                  <Avatar
                    src={review.image_url}
                    size="large"
                    className={classes.avatar}
                  />
                  <Box>
                    <Typography>{review.name}</Typography>
                    <Typography className={classes.testimonialPosition}>
                      {review.position}
                    </Typography>
                  </Box>
                </Box> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonial;