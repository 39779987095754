// import Header from "./header"
import About from "./about"
import Testimonial from "./testimonial"
import Footer from "./footer"
// import About from "./about"
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

import Header2 from "../header/header2";



const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },});

function AboutPage() {
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Header /> */}
      <Header2 />
      {/* <Hero />
      <Section /> */}
      <About />
      <Testimonial />
      {/* <Contact /> */}
      <Footer />
      </ThemeProvider>
    </>

  );
}

export default AboutPage;