import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
// import bestTeams from '../assets/bestTeams.jpeg';
import useStyles from './aboutstyle';

const About = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={5}>
          <img src={"../assets/small-team-discussing-ideas-2.png"} alt="Inotask Team" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight={700} className={classes.title}>
            Organize, Prioritize and Make an Impact
          </Typography>
          <Typography className={classes.aboutUsSubtitle}>
          Inotask is a tool that can help someone prioritize 
          tasks or projects based on specific criteria. By using Inotask, an
          individual can systematically evaluate and compare different tasks or projects, and 
          identify the most important ones to focus on. This can help them allocate their 
          time and resources more effectively, and ensure that they are working on the most 
          impactful or important items on their to-do list.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px', backgroundColor: '#EEB419', color: '#fff', ':hover': {
              bgcolor: '#f2f0f1',
              color: '#000', }}}
          >
            CONTACT US
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;