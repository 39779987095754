import Modal from "./modal"
import {useState} from 'react'
import './addtask.css'
import {db} from '../../services/firebase'
import {collection, addDoc, Timestamp} from 'firebase/firestore'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebase";

import {
  MuiPickersUtilsProvider, 
  DatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

function AddTask({onClose, open, quadrant}) {

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const [user] = useAuthState(auth);

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  /* function to add new task to firestore */
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addDoc(collection(db, 'tasks'), {
        title: title,
        description: description,
        completed: false,
        created: Timestamp.now(),
        // created: Date.now(),
        deleted: false,
        quadrant: {quadrant}.quadrant,
        userid: user.uid,
        duedate: selectedDate,
      })

      onClose()
    } catch (err) {
      alert(err)
    }
  }

  return (
    <Modal modalLable='Add Task' onClose={onClose} open={open}>
      <form onSubmit={handleSubmit} className='addTask' name='addTask'>
        <input 
          type='text' 
          name='title' 
          onChange={(e) => setTitle(e.target.value)} 
          value={title}
          placeholder='Enter title'/>
        <textarea 
          onChange={(e) => setDescription(e.target.value)}
          placeholder='Enter task decription'
          value={description}>
        </textarea>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            label="Due Date"
            format="MM/dd/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            // onChange={(e) => setSelectedDate(e.target.value)}
          />
        
        </MuiPickersUtilsProvider>
        <button type='submit'>Done</button>
      </form> 
    </Modal>
  )
}

export default AddTask