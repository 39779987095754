const Tooltip = ({ description, direction, className }) => {
    const getDirection = () => {
      const appendedClass = className ? className : '';
      switch (direction) {
        case 'top':
        case 'bottom':
        case 'left':
          return `${direction} ${appendedClass}`;
        default:
          return `right ${appendedClass}`;
      }
    };
    return (
      <div className={`tooltip-wrapper ${getDirection()}`}>
        <img
          // src="https://img.icons8.com/color/50/000000/info.png"
          src="../assets/info.png"
          className="icon"
          alt = "Prioritizing steps"
        />
        <div className="description">{description}</div>
      </div>
    );
  };
  
  export default Tooltip;
  