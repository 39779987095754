import './App.css';
// import { Title } from './components/index'
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./components/accounts/register";
import Login from "./components/accounts/login";
import Profile from './components/accounts/profile';
import Reset from './components/accounts/reset';
// import Task from './components/tasks/task';
import Prioritize from './components/container/prioritize';
// import Header from './components/header/header';
import LandingPage from './components/landingpage/landingpage';
import AboutPage from './components/aboutpage/aboutpage';
// import Header2 from "./components/header/header2";
import Feedback from './components/container/feedback';


function App() {


  return (
    
    <div className="App">
      {/* <Header/> */}
      {/* <Header2/> */}
      {/* <Title/> */}
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/prioritize" element={<Prioritize />} />
        <Route exact path="/feedback" element={<Feedback />} />
        <Route exact path="/about" element={<AboutPage />} />
        

      </Routes>
    </Router>
    </div>
    
  );
}

export default App;
