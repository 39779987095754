import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {db} from "../../services/firebase";
import {collection, query, orderBy, onSnapshot, where} from "firebase/firestore"
import AddTask from '../tasks/addtask'
import Task from "../tasks/task";
import "./prioritize.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebase";

import Header2 from "../header/header2";
import Footer from "../landingpage/footer";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

import Tooltip from "../tooltip";

function Prioritize() {

    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    let userID = null;
    

    // useEffect(() => {
    //     if (loading) return;
    //     if (!user) return navigate("/login");    
    //   }, [user, loading]);
    
    const [openAddModalQ1, setOpenAddModalQ1] = useState(false)
    const [openAddModalQ2, setOpenAddModalQ2] = useState(false)
    const [openAddModalQ3, setOpenAddModalQ3] = useState(false)
    const [openAddModalQ4, setOpenAddModalQ4] = useState(false)

    const [tasks, setTasks] = useState([])

    /* function to get all tasks from firestore in realtime */ 
    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/login");  
        userID = user.uid;

        const taskColRef = query(collection(db, 'tasks'), where("userid","==", userID), where("deleted", "==", false), where("completed", "==", false), orderBy('created', 'desc'))
        onSnapshot(taskColRef, (snapshot) => {
        setTasks(snapshot.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
        })))
        })
    },[user, loading])
   
    // console.log(tasks.filter(tasks => tasks.data.quadrant.includes("Q3")));

    const theme = createTheme({
        typography: {
          fontFamily: [
            'Poppins',
            'sans-serif',
          ].join(','),
        },});

    return (
      <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Header /> */}
      <Header2 />
        <div className="tasks">
            <div className="task_desc">
                <p>
                This grid outlines the importance of prioritization and focusing on changes
                that have the most impact. It uncovers the highly efficient method of being in the proactive
                state for all challenges and tasks that you're set to accomplish.
                </p>
                <p>
                Setting specific, measurable annual goals can help enhance your productivity.
                Start to pre-plan your days, weeks to better manage your time.
                Be specific when it comes to the time allocated for each task.                
                </p>
            </div>
            
            <div className="tasks_container">

                <div className="empty" ></div>
                <div className="urgent">Urgent</div>
                <div className="notUrgent">Not Urgent</div>
                <div className="important">Important</div>
                <div className="notImportant">Not Important</div>
                <div className="Q1">
                    {/* Add list of tasks here */}
                    <Tooltip
                        description={'Problems and crises that require immediate attention'}
                        direction={'bottom'}
                    />
                    <div className="desc">Problems and crises that require immediate attention</div>
                    <div className='taskManager'>
                        <div className='taskManager_container'>
                            {/* <button 
                            onClick={() => setOpenAddModalQ1(true)}>
                            Add task +
                            </button> */}
                            <div className='taskManager_tasks'>
                                {tasks.filter(tasks => tasks.data.quadrant.includes("Q1")).map((task) => (
                                    <Task
                                    id={task.id}
                                    key={task.id}
                                    completed={task.data.completed}
                                    title={task.data.title} 
                                    description={task.data.description}
                                    duedate={task.data.duedate}

                                    />
                                ))}
                            </div>
                            <button className="addNewTask"
                                onClick={() => setOpenAddModalQ1(true)}>
                                + Add task
                            </button>
                        </div>

                        {openAddModalQ1 &&
                            <AddTask onClose={() => setOpenAddModalQ1(false)} open={openAddModalQ1} quadrant={"Q1"} />
                        }

                    </div>
                </div>
                <div className="Q2">
                    <Tooltip
                        description={'Tasks that directly relate to your long-term goals'}
                        direction={'bottom'}
                    />
                    <div className="desc">Tasks that directly relate to your long-term goals</div>
                    <div className='taskManager'>
                        <div className='taskManager_container'>
                            <div className='taskManager_tasks'>
                                {tasks.filter(tasks => tasks.data.quadrant.includes("Q2")).map((task) => (
                                    <Task
                                    id={task.id}
                                    key={task.id}
                                    completed={task.data.completed}
                                    title={task.data.title} 
                                    description={task.data.description}
                                    />
                                ))}
                            </div>
                            <button className="addNewTask"
                                onClick={() => setOpenAddModalQ2(true)}>
                                + Add task
                            </button>
                        </div>

                        {openAddModalQ2 &&
                            <AddTask onClose={() => setOpenAddModalQ2(false)} open={openAddModalQ2} quadrant={"Q2"} />
                        }

                    </div>
                </div>
                <div className="Q3">
                    <Tooltip
                        description={'Tasks that seem urgent but hold minimal importance'}
                        direction={'bottom'}
                    />
                    <div className="desc">Tasks that take up time without producing any value</div>
                    <div className='taskManager'>
                        <div className='taskManager_container'>
                            <div className='taskManager_tasks'>
                                {tasks.filter(tasks => tasks.data.quadrant.includes("Q3")).map((task) => (
                                    <Task
                                    id={task.id}
                                    key={task.id}
                                    completed={task.data.completed}
                                    title={task.data.title} 
                                    description={task.data.description}
                                    />
                                ))}
                            </div>
                            <button className="addNewTask"
                                onClick={() => setOpenAddModalQ3(true)}>
                                + Add task
                            </button>
                        </div>

                        {openAddModalQ3 &&
                            <AddTask onClose={() => setOpenAddModalQ3(false)} open={openAddModalQ3} quadrant={"Q3"} />
                        }

                    </div>
                </div>
                <div className="Q4">
                    <Tooltip
                        description={'Tasks that take up time without producing any value'}
                        direction={'bottom'}
                    />
                    <div className="desc">Tasks that take up time without producing any value</div>
                    <div className='taskManager'>
                        <div className='taskManager_container'>
                            <div className='taskManager_tasks'>
                                {tasks.filter(tasks => tasks.data.quadrant.includes("Q4")).map((task) => (
                                    <Task
                                    id={task.id}
                                    key={task.id}
                                    completed={task.data.completed}
                                    title={task.data.title} 
                                    description={task.data.description}
                                    />
                                ))}
                            </div>
                            <button className="addNewTask"
                                onClick={() => setOpenAddModalQ4(true)}>
                                + Add task
                            </button>
                        </div>

                        {openAddModalQ4 &&
                            <AddTask onClose={() => setOpenAddModalQ4(false)} open={openAddModalQ4} quadrant={"Q4"} />
                        }

                    </div>
                </div>

            </div>

        </div>
        <Footer />
      </ThemeProvider>
       
    );
}

export default Prioritize;