import Modal from "./modal"
import {useState} from 'react'
import './edittask.css'
import { doc, updateDoc } from "firebase/firestore";
import {db} from '../../services/firebase'

import {
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';


function EditTask({open, onClose, toEditTitle, toEditDescription, toEditDueDate, id}) {
  
  const [title, setTitle] = useState(toEditTitle)
  const [description, setDescription] = useState(toEditDescription)
  const [duedate, SetDueDate] = useState(toEditDueDate != null ? (new Date(toEditDueDate.toDate())) : null)
  
  const handleDateChange = (date) => {
    SetDueDate(date);
  };

  /* function to update firestore */
  const handleUpdate = async (e) => {
    e.preventDefault()
    const taskDocRef = doc(db, 'tasks', id)
    try{
      await updateDoc(taskDocRef, {
        title: title,
        description: description,
        duedate: duedate,
      })
      onClose()
    } catch (err) {
      alert(err)
    }
    
  }

  return (
    <Modal modalLable='Edit Task' onClose={onClose} open={open}>
      <form onSubmit={handleUpdate} className='editTask'>
        <input type='text' name='title' onChange={(e) => setTitle(e.target.value)} value={title}/>
        <textarea onChange={(e) => setDescription(e.target.value)} value={description}></textarea>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            label="Due Date"
            format="MM/dd/yyyy"
            value={duedate}
            onChange={handleDateChange}
            // onChange={(e) => setSelectedDate(e.target.value)}
          />
        </MuiPickersUtilsProvider>
        <button type='submit'>Update</button>
      </form> 
    </Modal>
  )
}

export default EditTask