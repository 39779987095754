import {
    AppBar,
    Typography,
    Link,
    Box,
    Toolbar,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    IconButton,
    Drawer,
  } from '@mui/material';
import React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import useStyles from '../landingpage/landingpagestyle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { auth } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

  
  function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }
  
  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };
  
  const Header2 = (props) => {
    const classes = useStyles();
    // const links = [
    //   { id: 1, route: 'Login', url: '../login' },
    //   { id: 2, route: 'Start for free', url: '../register' },
    // ];
    let links = [];
    const [user] = useAuthState(auth);

    if(!user){
        links = [
          // { id: 1, route: 'About', url: '../about' },
          // { id: 2, route: 'Feedback', url: '../feedback' },
          { id: 3, route: 'Login', url: '../login' },
          { id: 4, route: 'Start For Free', url: '../register' },
          ];
    } else{
        links = [
            // { id: 1, route: 'About', url: '../about' },
            { id: 2, route: 'Tasks', url: '../prioritize' },
            // { id: 3, route: 'Feedback', url: '../feedback' },
            { id: 4, route: 'Profile', url: '../profile'},
          ];
    }
    
    const [state, setState] = React.useState({
      right: false,
    });
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };
  
    const list = (anchor) => (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {links.map((link) => (
            <ListItem button key={link.id}>
              <ListItemButton href={link.url}>
                <ListItemText primary={link.route}/>
              </ListItemButton>
            </ListItem>
            // <Link href={link.url} /*target="_blank"*/ underline="none" key={link.id}>
            //   <Typography className={classes.link}>{link.route}</Typography>
            // </Link>
          ))}
        </List>
      </Box>
    );
  
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
      
    return (
      <Box sx={{ marginBottom: '10px' }}>
        <ElevationScroll {...props}>
          <AppBar>
            <Toolbar className={classes.toolBar}>
            <Link href="/" underline="none">
            {/* <Typography variant="h6" className={classes.logo}>
                Todo - Do What Matters Most
            </Typography> */}
            <img src={"/assets/inotask-logo-2.png"} alt="Inotask - Do What Matters Most" className={classes.logoImage} />
            </Link>

            {matches ? (
            <Box>
            <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer('right', true)}
            >
                <MenuIcon className={classes.menuIcon} fontSize="" />
            </IconButton>

            <Drawer
                anchor="right"
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                {list('right')}
            </Drawer>
            </Box>
            ): <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: '0.05',
            }}
            >
            {links.map((link) => (
                <Link href={link.url} /*target="_blank"*/ underline="none" key={link.id}>
                  <Typography className={classes.link}>{link.route}</Typography>
                </Link>
            ))}
            </Box>}
            
                </Toolbar>
          </AppBar>
        </ElevationScroll>
      </Box>
    );
  };
  
  export default Header2;