import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./profile.css";
import { auth, db, logout } from "../../services/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";


import Header2 from "../header/header2";
import Footer from "../landingpage/footer";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },});

function Profile() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
  }, [user, loading]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Header /> */}
      <Header2 />
      <div className="profile">
        <div className="profile__container">
          Logged in as
          <div>{name}</div>
          <div>{user?.email}</div>
          <button className="profile__btn" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
}

export default Profile;