import './task.css'
import {useState} from 'react'
// import TaskItem from './taskitem'
import EditTask from './edittask'
import { doc, updateDoc, Timestamp} from "firebase/firestore";
// import { deleteDoc} from "firebase/firestore";
import {db} from '../../services/firebase'


function Task({id, title, description, completed, duedate}) {

  const [checked, setChecked] = useState(completed)
  const [open, setOpen] = useState({edit:false, view:false})

  const handleClose = () => {
    setOpen({edit:false, view:false})
  }

  /* function to update firestore */
  const handleChange = async () => {
    const taskDocRef = doc(db, 'tasks', id)
    try{
      await updateDoc(taskDocRef, {
        completed: checked,
        completedate: Timestamp.now(),
      })
    } catch (err) {
      alert(err)
    }
  }

    /* function to update firestore on delete - does not delete data */
    // const handleDelete = async () => {
    //   const taskDocRef = doc(db, 'tasks', id)
    //   try{
    //     await updateDoc(taskDocRef, {
    //       deleted: true
    //     })
    //   } catch (err) {
    //     alert(err)
    //   }
    // }

  /* function to delete a document from firstore */ 
  // const handleDelete = async () => {
  //   const taskDocRef = doc(db, 'tasks', id)
  //   try{
  //     await deleteDoc(taskDocRef)
  //   } catch (err) {
  //     alert(err)
  //   }
  // }

  return (
    <div className={`task ${checked && 'task--borderColor'}`}>
      <div>
        <input 
          id={`checkbox-${id}`} 
          className='checkbox-custom'
          name="checkbox" 
          checked={checked}
          onChange={handleChange}
          type="checkbox" />
        <label 
          htmlFor={`checkbox-${id}`} 
          className="checkbox-custom-label" 
          onClick={() => setChecked(!checked)} ></label>
      </div>
      <div className='task_body' onClick={() => setOpen({...open, edit : true})}>
        <p>{title}</p>
        {/* <p>{description}</p> */}
        {/* <div className='task_buttons'>
          <div className='task_deleteNedit'>
            <button 
              className='task_editButton' 
              onClick={() => setOpen({...open, edit : true})}>
              Edit
            </button>
            <button className='task_deleteButton' onClick={handleDelete}>Delete</button>
          </div>
          <button 
            onClick={() => setOpen({...open, view: true})}>
            View
          </button>
        </div> */}
      </div>

      {/* {open.view &&
        <TaskItem 
          onClose={handleClose} 
          title={title} 
          description={description} 
          open={open.view} />
      } */}

      {open.edit &&
        <EditTask 
          onClose={handleClose} 
          toEditTitle={title} 
          toEditDescription={description} 
          toEditDueDate={duedate}
          open={open.edit}
          id={id} />
      }

    </div>
  )
}

export default Task