import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
// import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
// import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
// import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import useStyles from './landingpagestyle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import MoreTimeIcon from '@mui/icons-material/MoreTime';


const Section = () => {
  const classes = useStyles();

  const sectionItems = [
    {
      id: 1,
      // icon: <LowPriorityIcon sx={{ fontSize: 60, color:'#EEB419' }}  />,
      icon: <LowPriorityIcon sx={{ fontSize: 60 }}  />,
      sentence:
        'Reprioritize the importance and urgency of your current and upcoming tasks by sorting the tasks and responsibilities into the appropriate. It empowers you to quickly identify activities that need your attention.',
    },
    {
      id: 2,
      icon: <MoreTimeIcon sx={{ fontSize: 60 }} />,
      sentence:
        'Utilize the matrix to question whether a certain activity brings you closer to your goals or not - the goal is to remain in the Urgent-Not Important section.',
    },
    {
      id: 3,
      icon: <EventNoteIcon sx={{ fontSize: 60 }} />,
      sentence: 'This method allows you to organize your priorities much better with the 24 hours you have in a day.',
    },
  ];
  return (
    // <Box sx={{ flexGrow: 1, minHeight: '400px' }}>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container className={classes.sectionGridContainer}>
        {sectionItems.map((item) => (
          <Grid
            item
            xs={12}
            md={3.5}
            minHeight={300}
            key={item.id}
            className={classes.sectionGridItem}
          >
            {item.icon}
            <Typography>{item.sentence}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Section;