import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  TextareaAutosize,
  Button,
} from '@mui/material';
import useStyles from './landingpagestyle';
import { submitMessage } from "../../services/firebase";


const Contact = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  // const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const classes = useStyles();

  const submitForm = (e) => {
    e.preventDefault();
    // console.log({ email, name, message });
    if (!email) alert("Please enter email");
    else if (!name) alert("Please enter name");
    else if (!message) alert("Please share a message!")
    else 
      submitMessage(email, name, message);
          setEmail('');
          setName('');
          setMessage('');
  };

  return (
    <Box className={classes.formContainer}>
      <Typography variant="h4" className={classes.formHeading}>
        Contact Us
      </Typography>
      <Box
        className={classes.form}
        // component="form"
        noValidate
        autoComplete="off"
      >

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {/* <TextField
          label="Subject"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        /> */}

        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder="Leave a message"
          className={classes.textArea}
          spellCheck
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{ width: '200px', fontSize: '16px', backgroundColor: '#EEB419', color: '#fff', ':hover': {
            bgcolor: '#f2f0f1',
            color: '#000', }}}
          onClick={submitForm}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Contact;