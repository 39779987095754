import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, submitFeedback } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./feedback.css";

import Header2 from "../header/header2";
import Footer from "../landingpage/footer";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

function Feedback() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    // if (user) navigate("/");
  }, [user, loading]);

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    },});

    const submitAFeedback = () => {
        if (!email) alert("Please enter email");
        else if (!name) alert("Please enter name");
        else if (!feedback) alert("Please share your thoughts!")
        else 
            submitFeedback(email, name, feedback);
            setEmail('');
            setName('');
            setFeedback('');
        };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Header /> */}
      <Header2 />
    <div className="feedback">
      <div className="feedback_container">
        {/* <img src={"/assets/inotask-logo-2.png"} alt="Inotask - Do What Matters Most" className="logoImage" /> */}
        <input
          type="text"
          className="feedback_textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="text"
          className="feedback_textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
        />
        <textarea 
          type="text"
          className="feedback_textArea"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Tell us what you think about Inotask"
        />
        <button
          className="feedback_btn"
          onClick={() => submitAFeedback()}
        >
          Submit
        </button>
        {/* <button className="feedback_btn feedback_google" onClick={signInWithGoogle}>
          feedback with Google
        </button> */}
        {/* <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div> */}
      </div>
      {/* <div className="feedback_container_img">
        <img src="/assets/small-team-discussing-ideas.png" alt="discussion" width="100%" height="auto%"/>
      </div> */}
    </div>
    <Footer />
      </ThemeProvider>
  );
}

export default Feedback;