import React from 'react';
import { Box, Typography } from '@mui/material';
// import { Link } from '@mui/material';
import useStyles from './aboutstyle';

const Footer = () => {
  // const date = new Date().getFullYear();
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      <Typography className={classes.footerDate}>Copyright © 2022 All rights reserved</Typography>
      {/* <Typography className={classes.footerText}>
        <Link href="https://appseed.us" target="_blank" underline="none">
          Terms & Conditions
        </Link> &nbsp;
        <Link href="https://appseed.us" target="_blank" underline="none">
          Privacy Policy
        </Link>
      </Typography> */}
    </Box>
  );
};

export default Footer;